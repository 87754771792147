import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#03A499';
const footerRelatedLinks = [
	{ title: 'MJF Swipe app', url: '/fr/projets/montreux-jazz-festival-swipe-app/' },
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_hero.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/rachis-clinic/rachis_clinic_hero_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/rachis-clinic/rachis_clinic_dna.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_2.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_3.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_4.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_5.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_6.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/rachis-clinic/',
					lang: 'en',
				},
			]}
			title="Rachis Clinic - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Rachis Clinic"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Bougez de nouveau avec sérénité sans souffrir de douleurs
							au dos. Rachis Clinic est la spécialiste de l'appareil
							locomoteur complexe !
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Positionnement de marque',
								'Branding',
								'Identité visuelle',
								'Site internet'
							]}
						/>
					</div>
					<div className="project-description text-content">
						<p>
							La Rachis Clinic propose une approche interdisciplinaire
							d'ergothérapeutes, de physiothérapeutes, de spécialistes
							du dos et de l'appareil locomoteur complexe. Sa force est
							de pouvoir vous accompagner en coordonnant les différents
							spécialistes.
						</p>
						<h3>Le challenge</h3>
						<p>
							Société nouvellement créée, la Rachis Clinic était empreinte
							d'une vision claire et forte, mais nécessitait un travail
							approfondi de positionnement et d'accompagnement pour
							définir sa future marque pour son lancement et son
							développement. L'accompagnement intégrait les sous-marques
							Wellness et Programme Rachis.
						</p>

						<h3>Notre solution</h3>
						<p>
							Portée par une série de workshops pour définir la personnalité
							de la marque, le positionnement en relation avec son marché
							et son audience ainsi que les messages de marque. Rachis
							Clinic exprime pleinement son engagement en faveur de
							l'excellence tout en se différenciant du marché par
							une expression visuelle avant-gardiste et attractive.
						</p>
					</div>
				</div>

				<Video
					className="col-full"
					src="dd88ef2d13442d76addcbe77df64f585"
					alt=""
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote={
						<>
							Retrouvez l'équilibre et le plaisir de bouger au quotidien
						</>
					}
					more="La médecine traite principalement la douleur, alors que la Rachis Clinic se concentre sur ses causes, travaillant sur l'équilibre de vie grâce à son approche holistique dans le but d'aider à ce que les douleurs dorsales ne soient plus un obstacle."
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>
							Inspirés par les métiers de l'ergothérapie et de la physiothérapie
						</h3>
						<p>
							Les visuels de marque sont inspirés par les formes du matériel
							utilisé pour les exercices d'ergothérapie et de physiothérapie
							ainsi que par certaines formes des structures osseuses.
						</p>
					</div>

					<div className="column__media text-content">
						<Video
							src="b585cd086d013e9614505f1105fc8923"
							alt=""
						/>
						<Video
							src="691c4b71276b2672267f90bad5f1c643"
							alt=""
						/>
					</div>
				</Column>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>Brand DNA</h3>
							<p>
								Nos workshops de branding ont permis d'identifier les fondamentaux
								de la marque et de les traduire pour proposer une marque forte,
								cohérente et avec un important potentiel de croissance.
							</p>
						</div>
						<Image {...data.image_1.childImageSharp} alt="Logo" />
					</div>
				</div>

				<Image
					className="col-full"
					{...data.image_2.childImageSharp}
					alt="Typography"
				/>
				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image
						{...data.image_3.childImageSharp}
						alt="Trame visuelle de la marque"
					/>
					<Image
						{...data.image_4.childImageSharp}
						alt="Symbole du sourire sur fond turquoise"
					/>
				</Gallery>

				<Column modifiers="left">
					<div className="column__media text-content">
						<Image
							{...data.image_5.childImageSharp}
							alt="Photo d'un médecin"
						/>
						<Image
							{...data.image_6.childImageSharp}
							alt="Photo d'une fiole"
						/>
					</div>
					<div className="column__text column__sticky text-content">
						<h3>
							Captivante et avant-gardiste
						</h3>
						<p>
							L’expression visuelle captive l’attention
							avec des graphismes en différenciation par
							rapport au domaine médical. Tout en
							exprimant l’ADN avant-gardiste de la
							démarche de la clinique.
						</p>
					</div>
				</Column>

				<Blockquote
					uptitle="Témoignage client"
					quote="« Les workshops et le positionnement nous ont permis de structurer notre société, l’ADN a d’ailleurs parfaitement été traduit dans notre marque. Ce qui facilite le recrutement dans un marché tendu, mais aussi notre positionnement de clinique d’excellence »"
					cite="Damiano Salvi, CEO"
				/>
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
